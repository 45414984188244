const toggleNav = function toggleNav() {
    const mobileNav = document.querySelector('.mobile-nav');
    if (mobileNav.getAttribute('aria-modal') === 'true') {
        mobileNav.setAttribute('aria-modal', 'false');
        document.body.classList.remove('offcanvas-open');
    } else {
        mobileNav.setAttribute('aria-modal', 'true');
        document.body.classList.add('offcanvas-open');
    }
};

const mobileToggle = document.querySelector('.mobile-toggle');
const mobileNavClose = document.querySelector('.mobile-nav .close');

mobileToggle.addEventListener('click', toggleNav);
mobileNavClose.addEventListener('click', toggleNav);
